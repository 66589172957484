<template>
  <Page v-bind="page">
    <div
      class="
        l-container l-container--small
        u-margin-top--2
        u-margin-bottom--4
        sm:u-margin-top--6
      "
    >
      <ErrorBoundary v-if="introduction">
        <Introduction
          v-bind="introduction"
          id="overview"
          data-anchor-text="Overview"
        />
      </ErrorBoundary>
    </div>
    <ErrorBoundary>
      <SubNavigation />
    </ErrorBoundary>
    <div class="l-container">
      <ErrorBoundary v-if="departuresByMonth?.length">
        <ErrorBoundary>
          <DepartureFeaturedList
            id="travel-deals"
            class="u-margin-top--4 sm:u-margin-top--6"
            :destinationName="destinationDisplayName"
            :destinationType="destinationType"
            :destinationMonth="`${destinationMonth?.toLowerCase()}`"
            :destinationTitle="`${destinationDisplayName} trips in ${destinationMonth} ${destinationYear}`"
            :destinationNoDeparturesTitle="`We’ve got no trips departing in ${destinationMonth} ${destinationYear}`"
            data-anchor-text="Our trips"
          />
        </ErrorBoundary>
        <DestinationByMonthList
          id="departures-by-month"
          data-anchor-text="Departures by month"
          class="u-margin-top--4 sm:u-margin-top--6"
          :destinationName="destinationDisplayName"
          :months="departuresByMonth"
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <Reviews
          v-if="reviews?.reviewComments.length"
          data-anchor-text="Reviews"
          v-bind="reviewAggregate"
          :reviews="reviews"
          :month="destinationMonth"
          class="u-margin-top--4 sm:u-margin-top--6"
        >
          <h2 slot="title">{{ destinationMonth }} reviews</h2>
        </Reviews>
      </ErrorBoundary>
      <ErrorBoundary>
        <FaqsCTA
          v-if="faqsCTA && destinationDisplayName && destinationUrl"
          :destination-name="destinationDisplayName"
          :destination-url="destinationUrl"
          class="u-margin-top--4 sm:u-margin-top--6"
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <BlogCarousel
          v-if="blogCarousel"
          id="articles"
          data-anchor-text="Articles"
          v-bind="blogCarousel"
          class="u-margin-top--4 sm:u-margin-top--6"
          carouselTitle="Get inspired on The Good Times"
        />
      </ErrorBoundary>
    </div>
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { WithContext, Product } from "schema-dts";
import Page from "~/components/Page/Page.vue";
import BlogCarousel from "~/components/BlogCarousel/BlogCarousel.vue";
import DestinationByMonthList from "~/components/DestinationByMonthList/DestinationByMonthList.vue";
import Introduction from "~/components/Introduction/Introduction.vue";
import Reviews from "~/components/Reviews/Reviews.vue";
import SubNavigation from "~/components/SubNavigation/SubNavigation.vue";
import { DestinationMonthsPageProps } from "~/components/DestinationMonthsPage/Props";
import ErrorBoundary from "~/components/ErrorBoundary/ErrorBoundary.vue";
import DepartureFeaturedList from "~/components/DepartureFeaturedList/DepartureFeaturedList.vue";
import FaqsCTA from "~/components/FaqsCTA/FaqsCTA.vue";

export default Vue.extend({
  name: "DestinationMonthsPage",
  components: {
    Page,
    ErrorBoundary,
    BlogCarousel,
    Introduction,
    SubNavigation,
    FaqsCTA,
    DestinationByMonthList,
    DepartureFeaturedList,
    Reviews,
  },
  props: {
    page: {
      type: Object as PropType<DestinationMonthsPageProps["page"]>,
      required: true,
    },
    introduction: {
      type: Object as PropType<DestinationMonthsPageProps["introduction"]>,
      required: false,
      default: undefined,
    },
    destinationMonth: {
      type: String as PropType<DestinationMonthsPageProps["destinationMonth"]>,
      required: false,
      default: undefined,
    },
    blogCarousel: {
      type: Object as PropType<DestinationMonthsPageProps["blogCarousel"]>,
      required: false,
      default: undefined,
    },
    destinationDisplayName: {
      type: String as PropType<
        DestinationMonthsPageProps["destinationDisplayName"]
      >,
      required: true,
    },
    destinationUrl: {
      type: String as PropType<DestinationMonthsPageProps["destinationUrl"]>,
      required: true,
    },
    faqsCTA: {
      type: Boolean,
      required: true,
    },
    departuresByMonth: {
      type: Array as PropType<DestinationMonthsPageProps["departuresByMonth"]>,
      required: false,
      default: undefined,
    },
    destinationType: {
      type: String as PropType<DestinationMonthsPageProps["destinationType"]>,
      required: false,
      default: undefined,
    },
    destinationYear: {
      type: String as PropType<DestinationMonthsPageProps["destinationYear"]>,
      required: false,
      default: undefined,
    },
    reviewAggregate: {
      type: Object as PropType<DestinationMonthsPageProps["reviewAggregate"]>,
      required: false,
      default: undefined,
    },
    reviews: {
      type: Object as PropType<DestinationMonthsPageProps["reviews"]>,
      required: false,
      default: undefined,
    },
  },
  created() {
    this.updateAggregateSchema();
  },
  methods: {
    updateAggregateSchema() {
      if (this.page?.jsonld) {
        (this.page?.jsonld as unknown as WithContext<Product>[]).map(
          (jsonLd) => {
            if (
              jsonLd["@type"] === "Product" &&
              this.reviewAggregate?.averageRating &&
              this.reviewAggregate?.reviewsCount
            ) {
              jsonLd.aggregateRating = {
                "@type": "AggregateRating",
                ratingValue: this.reviewAggregate?.averageRating,
                reviewCount: this.reviewAggregate?.reviewsCount,
              };
            }

            return jsonLd;
          }
        );
      }
    },
  },
});
</script>
